import { apiTurnos } from '@/core/api/';

export const getOrganizations = () => apiTurnos.get('api/organization/getall');

export const getOrganizationsByService = serviceId =>
  apiTurnos.get('api/organization/byserviceid', { params: { serviceId } });

export const getOrganizationList = (payload) => apiTurnos.get('/api/organization/search', { params: { ...payload } });

export const createOrganization = (payload) => apiTurnos.post('/api/organization', payload);

export const updateOrganization = (payload) => apiTurnos.put('/api/organization', payload);
