<template>
  <section>
    <content-header :title="$t('organizationsMaintenance')" />
    <div class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t('organizationFilter')" />
        </div>
        <filter-manager
          ref="filterComponent"
          v-model="selectedFilters"
          class="mb-4"
          :display-advanced="false"
          :filters="filtersConfig"
          :search-text="$t('filter')"
          @search="getOrganizations(1)"
        />
      </div>
      <CustomNotFound
        v-if="noResultsFound"
        :text="$t('noResultsFound')"
      />
      <separator
        class="my-4"
        :text="$t('listOfOrganizations')"
      />
      <div class="row mt-4 mb-3">
        <div class="col-sm-12">
          <base-filled-button
            class="float-right"
            icon-class="fas fa-plus"
            :on-click="() => openCreateModal()"
            :text="$t('create')"
          />
        </div>
      </div>
      <div class="row">
        <div
          v-for="(organization, index) in organizations.data"
          :key="index"
          class="mt-3 col-12"
        >
          <search-result
            :value="organization"
            :index="index"
            :index-in-edit-mode="indexInEditMode"
            @edit="indexInEditMode = $event"
          />
        </div>
      </div>
      <custom-pagination
        v-if="organizations.count > 0"
        class="my-4"
        :data="organizations"
        :page-size="pageSize"
        @pagination-go-page="getOrganizations($event)"
        @pagination-rows-per-page="getOrganizations(pageIndex, $event)"
      />
       <custom-modal
      v-model="showCreateModal"
       size="md"
      :title="$t('organizationMaintenance')"

    >
      <validation-observer ref="formRef" novalidate>
      <div class="row">
        <div class="col-md-12">
          <validation-provider v-slot="{ errors }" rules="required|max:128">
            <base-input
              v-model="model.organizationName"
              :error="errors[0]"
              :error-msg="$t('invalidField')"
              :label="$t('name')"
            />
          </validation-provider>
        </div>
        <div class="col-md-12 text-right mt-3">
          <base-filled-button
            bg-color="#707070"
            class="mx-0 float-left"
            icon-class="fas fa-ban"
            :on-click="() => showCreateModal = false"
            :text="$t('cancel')"
          />

          <button
            class="btn btn-outline-primary"
            variant="outline-primary"
            @click="onSubmit()"
          >
            <i class="far fa-save" /> {{ $t('save') }}
          </button>
        </div>
      </div>
      </validation-observer>
    </custom-modal>
    </div>
  </section>
</template>
<script>
import contentHeader from '@/components/Header';
import CustomModal from '@/components/basics/modal/CustomModal';
import SubHeader from '@/components/SubHeader.vue';
import FilterManager from '@/components/FilterManager';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import Separator from '@/components/Separator';
import SearchResult from './components/SearchResult.vue';
import CustomPagination from '@/components/CustomPagination.vue';
import {
  getOrganizationList as _getOrganizationList,
  createOrganization as _createOrganization
} from '@/services/OrganizationService';
import sessionState from '@/mixins/sessionState';
import BaseInput from '@/components/BaseInput.vue';

export default {
  name: 'RolessList',
  components: {
    contentHeader,
    SubHeader,
    FilterManager,
    CustomNotFound,
    Separator,
    SearchResult,
    CustomPagination,
    CustomModal,
    BaseInput
  },
  data() {
    return {
      selectedFilters: {
        name: '',
      },
      organizations: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      noResultsFound: false,
      pageSize: 10,
      showCreateModal: false,
      pageIndex: 1,
      selectedRole: null,
      model: {
        organizationName: null
      },
      indexInEditMode: null
    };
  },
  mixins: [sessionState],
  computed: {
    filtersConfig() {
      return [
        {
          rules: '',
          name: 'name',
          label: this.$t('name'),
          component: 'BaseInput',
          placeholder: this.$t('name'),
          fieldtext: 'name',
          fieldvalue: 'id',
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.name,
          value: this.selectedFilters.name,
        },
      ];
    },
  },
  methods: {
    openCreateModal() {
      this.model = {
        organizationName: null
      }
      this.showCreateModal = true;
    },
    async getOrganizations(pageIndex = null, pageSize = null) {
      this.pageIndex = pageIndex || this.pageIndex;
      this.pageSize = pageSize || this.pageSize;

      const payload = {
        name: this.selectedFilters.name,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
      };

      await _getOrganizationList(payload)
        .then(({ data }) => {
          this.organizations = data;
        })
    },
    async onSubmit() {
      if (!(await this.$refs.formRef.validate())) return;

      _createOrganization(this.model).then(() => {
        this.ShowSuccessSaveToast();
        this.showCreateModal = false;
        this.getOrganizations();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep h6 {
  color: $color-primary;
}
::v-deep textarea {
  resize: none;
}
</style>
