<template>
  <div class="p-3 listview card border-light">
    <validation-observer ref="formRef" novalidate>
      <div class="row">
        <div class="col-12 col-sm-12 col-md-4">
          <p>
            <b>{{ $t('organization') }}</b>
            <br />
            <span v-if="!isEditMode">{{ value.organizationName }}</span>
            <validation-provider
              v-else
              v-slot="{ errors }"
              rules="required|max:128"
            >
              <base-input
                v-model="model.organizationName"
                :error="errors[0]"
                :error-msg="$t('invalidField')"
                label=""
              />
            </validation-provider>
          </p>
        </div>
        <div class="col-12 col-sm-12 col-md-4">
          <p>
            <b>{{ $t('available') }}</b>
            <br />
            <span v-if="!isEditMode">{{
              $t(value.isActive ? 'yes' : 'no')
            }}</span>
            <b-form-checkbox v-if="isEditMode" v-model="model.isActive" switch>
            </b-form-checkbox>
          </p>
        </div>
        <div class="col-md-4 text-right">
          <button
            v-if="!isEditMode"
            @click="edit()"
            class="btn btn-outline-primary cursor-pointer"
          >
            {{ $t('edit') }}
          </button>
          <button
            v-if="isEditMode"
            class="btn btn-primary"
            style="width: 140px"
            @click="onSubmit()"
          >
            <i class="far fa-save" /> {{ $t('save') }}
          </button>
          <br />
          <button
            v-if="isEditMode"
            class="btn mt-2"
            style="background-color: #707070; width: 140px; color: white"
            @click="$emit('edit', null)"
          >
            <i class="fas fa-ban" /> {{ $t('cancel') }}
          </button>
        </div>
      </div>
    </validation-observer>
  </div>
</template>
<script>
import BaseInput from '@/components/BaseInput.vue';
import { updateOrganization as _updateOrganization } from '@/services/OrganizationService';

export default {
  name: 'OrganizationSearchResult',
  components: {
    BaseInput,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: null,
    },
    indexInEditMode: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      model: {},
    };
  },
  computed: {
    isEditMode() {
      return this.index === this.indexInEditMode;
    },
  },
  methods: {
    edit() {
      this.$emit('edit', this.index);
      this.model = { ...this.value };
    },
    async onSubmit() {
      if (!(await this.$refs.formRef.validate())) return;

      _updateOrganization(this.model).then(() => {
        this.ShowSuccessSaveToast();
        this.value.organizationName = this.model.organizationName;
        this.value.isActive = this.model.isActive;
        this.$emit('edit', null);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.listview .cell {
  clear: left;
}
.listview .cell label {
  float: left;
  font-weight: bold;
}
.listview .cell span,
::v-deep .formatted-text label {
  margin-left: 5px;
  color: $color-primary;
  font-weight: bold;
}
.card {
  word-break: break-word;
}
</style>
